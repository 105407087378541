const config = {
  Amplify: {
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOLID,
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_COGNITO_REGION,
      // OPTIONAL - Amazon Cognito Federated Identity Pool Region
      // Required only if it's different from Amazon Cognito Region
      identityPoolRegion: process.env.REACT_APP_COGNITO_REGION,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_COGNITO_USERPOOLID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOLWEBCLIENTID,
      // OPTIONAL - Hosted UI configuration
      oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: [
          "phone",
          "email",
          "profile",
          "openid",
          "aws.cognito.signin.user.admin",
        ],
        redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
        redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT,
        responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
      },
    },
  },
  SLS: {
    baseUrl: process.env.REACT_APP_SLS_BASE_ENDPOINT,
    apiKey: process.env.REACT_APP_SLS_API_KEY,
  },
  SSO: {
    provider: process.env.REACT_APP_SSO_PROVIDER,
    clientId: process.env.REACT_APP_SSO_CLIENT_ID,
    redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
    redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT,
  },
  fileUploadConfig: {
    bucketName: process.env.REACT_APP_FILE_UPLOAD_BUCKET_NAME,
    apiKey: process.env.REACT_APP_FILE_UPLOAD_API_KEY,
  },
  Chat: {
    baseId: process.env.REACT_APP_HZ_BASE_ID,
  },
  isLocal: process.env.REACT_APP_IS_LOCAL === "true",
  defaultClient: process.env.REACT_APP_DEFAULT_CLIENT,
  language: "EN-US",
  secretKey: process.env.REACT_APP_HZ_SECRET_KEY,
  gsxValidation: process.env.REACT_APP_GSX_VALIDATION === "true",
  singoreTax: 7,
  RRPTax: 50,
  gtmId: process.env.REACT_APP_GTM_ID,
  sampleFile: process.env.REACT_APP_SAMPLE_FILE_UPLOAD_BUCKET_NAME,
  BulkFile: process.env.REACT_APP_BULK_FILE_UPLOAD_BUCKET_NAME,
};

export default config;
