import axios from "axios";

export default function callAPI({
  url,
  method,
  headers,
  data,
  onUploadProgress,
  callback,
}) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url,
      headers,
      data,
      onUploadProgress,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
        callback && callback(error);
      });
  });
}
