import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Amplify from "@aws-amplify/auth";
import { AuthProvider } from "./helpers/AuthProvider";

import Routes from "./Routes";

import customerDataReducer from "./reducers/customerDataReducer";
import { StateProvider } from "./state";
import config from "./config";

import "./index.css";
import { RequestType } from "./utils/constants";
import moment from "moment";
import TagManager from 'react-gtm-module';

Amplify.configure(config.Amplify); // pass manual config

//GTM code integration
const tagManagerArgs = {
  gtmId: config.gtmId
};

function App() {
  //GTM code integration
  TagManager.initialize(tagManagerArgs);

  let defaultDate = new Date().toISOString().slice(0, 10);
  let fifteenDayAgoDate = moment().add(-15, "days").toISOString().slice(0, 10);

  const initialState = {
    isSso: false,
    user: {},
    userEmail: "",
    initAppData: null,
    sessionId: null,
    loadingState: true,
    loginLoader: true,
    walkinRecords: [],
    WalkinClaimsRecords:[],
    purRecords: [],
    homePlusRecords: [],
    vasRecords:[],
    mailinRecords: [],
    allRecords: [],
    allUniqueAspPartsList: [],
    exceptionsRecords: [],
    aspRecords: [
      {
        providerName: "TW Samsung Seven Hills",
        providerLocation: "New South Wales",
        providerCode: "TS NSW 31",
        providerClient: ["Australia Digital"],
        assetMake: ["Apple"],
        supportedRepairTypes: ["Mail-in"],
        aspLabourMargin: "50.00",
        city: "Sydney",
        state: "New South Wales",
        client: "Australia Digital",
        dateAdded: moment().format("DD/MM/YYYY"),
        addressLine1: "D2/391 Park Rd",
        addressLine2: "Regents Park",
        addressLine3: "NSW",
        cityName: "Richmond",
        stateCode: "NSW",
        countryCode: "AUS",
        postalCode: "2143",
        latitude: "-33.880662092311645",
        longitude: "151.02103836138048",
        weekdaySchedule: {
          workDays: ["Monday", "Wednesday", "Friday"],
          aspStartTime: "08:00 AM",
          aspCloseTime: "05:00 PM",
          appointmentDurationNo: "3",
          appointmentDurationType: "Hours",
          appointmentSlots: [
            {
              appointmentTimeSlot: "09:00 AM to 10:00 AM",
              appointmentPerSlot: "3",
              minutesPerSlot: "20",
            },
            {
              appointmentTimeSlot: "10:00 AM to 11:00 AM",
              appointmentPerSlot: "3",
              minutesPerSlot: "20",
            },
            {
              appointmentTimeSlot: "11:00 AM to 12:00 PM",
              appointmentPerSlot: "3",
              minutesPerSlot: "20",
            },
          ],
          repairStartTime: "09:00 AM",
          repirEndTime: "05:00 PM",
        },
        weekendSchedule: {
          workDays: ["Saturday", "Sunday"],
          aspStartTime: "08:00 AM",
          aspCloseTime: "12:00 PM",
          appointmentDurationNo: "1",
          appointmentDurationType: "Hours",
          appointmentSlots: [
            {
              appointmentTimeSlot: "08:00 AM to 09:00 AM",
              appointmentPerSlot: "3",
              minutesPerSlot: "20",
            },
            {
              appointmentTimeSlot: "09:00 AM to 10:00 AM",
              appointmentPerSlot: "3",
              minutesPerSlot: "20",
            },
            {
              appointmentTimeSlot: "10:00 AM to 11:00 AM",
              appointmentPerSlot: "3",
              minutesPerSlot: "20",
            },
            {
              appointmentTimeSlot: "11:00 AM to 12:00 AM",
              appointmentPerSlot: "3",
              minutesPerSlot: "20",
            },
          ],
          repairStartTime: "09:00 AM",
          repirEndTime: "12:00 PM",
        },
      },
    ],
    scheduledCount: 0,
    inProgressCount: 0,
    completedCount: 0,
    scheduledCountWalkinClaims: 0,
    inProgressCountWalkinClaims: 0,
    completedCountWalkinClaims: 0,
    selectedTab: "Mail-in",
    isTLCTab: false,
    clientName: config.defaultClient,
    recordsStatusFilter: "Scheduled",
    popup: {
      show: false,
      title: "",
      message: "",
    },
    walkinFilters: {
      AppointmentDate: {
        AppointmentCreatedDateFrom: defaultDate,
        AppointmentCreatedDateTo: defaultDate,
      },
      RequestType: RequestType.WALKIN,
    },
    purFilters: {
      AppointmentDate: {
        AppointmentCreatedDateFrom: defaultDate,
        AppointmentCreatedDateTo: defaultDate,
      },
      RequestType: RequestType.PUR,
    },
    HomePlusFilters: {
      AppointmentDate: {
        AppointmentCreatedDateFrom: defaultDate,
        AppointmentCreatedDateTo: defaultDate,
      },
      RequestType: RequestType.HOMEPLUS,
    },
    VASFilters:{
      AppointmentDate: {
        AppointmentCreatedDateFrom: defaultDate,
        AppointmentCreatedDateTo: defaultDate,
      },
      RequestType: RequestType.VAS,
    },
    mailinFilters: {
      AppointmentDate: {
        AppointmentCreatedDateFrom: fifteenDayAgoDate,
        AppointmentCreatedDateTo: defaultDate,
      },
      RequestType: RequestType.MAILIN,
    },
    aspOnboardingFilters: {},
    allRecordsFilters: {
      Name: "ALL",
      RequestType: RequestType.PUR,
      PickupDate: {
        PickupDateFrom: fifteenDayAgoDate,
        PickupDateTo: defaultDate,
      },
    },
    defaultWalkinFilters: {
      AppointmentDate: {
        AppointmentCreatedDateFrom: defaultDate,
        AppointmentCreatedDateTo: defaultDate,
      },
      RequestType: RequestType.WALKIN,
    },
    defaultPURFilters: {
      AppointmentDate: {
        AppointmentCreatedDateFrom: defaultDate,
        AppointmentCreatedDateTo: defaultDate,
      },
      RequestType: RequestType.PUR,
    },
    defaultHomePlusFilters: {
      AppointmentDate: {
        AppointmentCreatedDateFrom: defaultDate,
        AppointmentCreatedDateTo: defaultDate,
      },
      RequestType: RequestType.HOMEPLUS,
    },
    defaultMailinFilters: {
      AppointmentDate: {
        AppointmentCreatedDateFrom: fifteenDayAgoDate,
        AppointmentCreatedDateTo: defaultDate,
      },
      RequestType: RequestType.MAILIN,
    },
    allRecordsDefaultFilters: {
      Name: "ALL",
      RequestType: RequestType.PUR,
      PickupDate: {
        PickupDateFrom: fifteenDayAgoDate,
        PickupDateTo: defaultDate,
      },
    },
    defaultAspOnboardingFilters: {},
    activeTLCRecord: {},
    currentTLCRecord: [],
    makeList: [],
    multipleSelectRecords: [],
    jwtData: {},
    jwtToken: "",
    jobsRecords: [],
    jobsFilters: {
      ClientName: [],
      CustomerCaseNo: "",
      DeliveryEndTimestamp: defaultDate,
      DeliveryStartTimestamp: defaultDate,
    },
    defaultJobsFilters: {
      ClientName: [],
      CustomerCaseNo: "",
      DeliveryEndTimestamp: defaultDate,
      DeliveryStartTimestamp: defaultDate,
    },
    GsxASN: [],
    GsxInvoiceDetails: [],
    GsxInvoiceComparison: [],
    GsxRepairCosts: [],
    GsxMappingTable: [],
    GsxMasterUI: [],

    IMEIVerificationFailedAttempt: 0,
    TrackingDetailsFlag: false,
    ProgramList:[{ key: "Select", value: "" }],
    CategoryList:[{ key: "Select", value: "" }],
    Currency:'',
    CurrencyList:  [
      {key:'Select',value:''},
      {key:'SGD',value:'SGD'},
      {key:'MYR',value:'MYR'},
      {key:'THB',value:'THB'},
      {key:'TWD',value:'TWD'}],
    findHeaderValidity:true,
    CategoryListCopy:[{ key: "Select", value: "" }],
    invalidFile:false
  };

  return (
    <div className="antialiased overflow-x-hidden">
      <StateProvider initialState={initialState} reducer={customerDataReducer}>
        <AuthProvider>
          <Router>
            <Routes />
          </Router>
        </AuthProvider>
      </StateProvider>
    </div>
  );
}

export default App;
