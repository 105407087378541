import React from "react";

const CONSTANTS = {
  CAPTCHAKEY: "6LfgUtEUAAAAAFnb4_oWR6VexBL-al8s7lhCFWUt",
  SETCAPTCHA: "SETCAPTCHA",
  ERRORMSG: {
    INCORRECT_DETAILS: "Incorrect email or password.",
    UNCONFIRMED_USER: "Incorrect email or password.",
    RESET_PASSWORD: "Please reset your password.",
    TECHNICAL_ERROR: "Technical Error occured.",
  },
  CREDIT_CARD_ALERT_SCRIPTS: {
    CRE_BASE: (
      <>
        Please click the <strong>confirm button</strong> below to charge the
        customer's credit card automatically
      </>
    ),
    COD_BASE: (
      <>
        Please click the <strong>confirm button</strong> below once cash payment
        is collected from customer
      </>
    ),
    VERIFYING_CRE_PAYMENT: "Verifying the credit card payment. Please wait...",
    VERIFYING_COD_PAYMENT: "Updating payment Info! Please wait...",
    COD_PAYMENT_SUCCESS: (
      <>
        The payment is <strong>successful!</strong>
        <br />
        Please <strong>tick DO signed by customer</strong> to confirm.
      </>
    ),
    CRE_PAYMENT_SUCCESS: (
      <>
        The credit card payment is <strong>successful!</strong>
        <br />
        Please <strong>tick DO signed by customer</strong> to confirm.
      </>
    ),
    CRE_PAYMENT_SUCCESS_UP: (
      <>
        The credit card payment is <strong>successful!</strong>
        <br />
        Please{" "}
        <strong>tick DO signed customer and upload DO signed file</strong> to
        confirm.
      </>
    ),
    CRE_PAYMENT_ERROR: (
      <>
        <strong>Credit card payment failed.</strong> MOP for this repair request
        is changed to <strong>COD</strong>.
        <label className="text-cherryRed font-semibold">
          Please collect cash from customer.
        </label>
      </>
    ),
    COD_PAYMENT_ERROR: (
      <>
        <strong>Payment failed.</strong> Try again!
      </>
    ),
  },
};

export default CONSTANTS;

export const UserRoles = {
  AOC_ADMIN: "AOC Admin",
  TLC_ADMIN: "TLC Admin",
  CARE_MNGR: "Care Manager",
  AOC_REP_TECH: "AOC Repair Technician",
  ASP_REP_TECH: "ASP Repair Technician",
  TECH_MAILIN: "Technician MAILIN",
  TECH_PUR: "Technician PUR",
  HOMEPLUS: "Home Plus",
  VAS: "VAS",
  TECH_WALKIN: "Technician WALKIN",
  RPR_MGMT: "Repair Management",
  DP_CLAIMS: "DP Claims",
  CHECK_IN: "Check In",
  ASP_ONBRD: "ASP Onboarding",
  PRT_MGMT: "Parts Management",
  ASP_RCVNG: "ASP Parts Receiving",
  INVT_MN_MX: "Inventory Min Max",
  INVT_UD_QTY: "Inventory Update Qty",
  READ_ONLY: "Horizon Service Read Only",
  ASP_SUR_ELIGIBLE: "ASP SUR Eligibility",
  ASSET_MANAGEMENT: "Asset Management",
  REPLACEMENT_MATRIX: "Replacement Matrix",
  ADD_NEW_DEVICE: "Add New Device",
  SUPPORTED_DEVICE: "Supported Devices",
  AOC_RECEIVING_SCREEN: "AOC Parts Receiving",
  ASP_RMA: "Asp Rma",
  GSX_CONSOLE: "GSX Console",
  GSX_MASTER_UI: "GSX Master UI",
  GSX_ASN: "GSX Asn",
  GSX_INVOICE_DETAILS: "GSX Invoice Details",
  GSX_INVOICE_COMPARISON: "GSX Invoice Comparison",
  GSX_REPAIR_COSTS: "GSX Repair Costs",
  GSX_MAPPING_TABLE: "GSX Mapping Table",
  GSX_SCM_APPROVE: "Update SCM Approve",
  GSX_FINANCE_APPROVE: "Update Finance Approve",
};

export const GTM_EVENT = {
  LOGIN_FAILED_EVENT: "ASP_DL_Event Login Failed",
  LOGIN_SUCCESS_EVENT: "ASP_DL_Event Login Success",
  USER_EMAIL_INITIALIZED: "ASP_DL_Event Initiated",
};

export const AppFeatures = {
  // ADMIN CONSOLE
  ADMIN_CONSOL: "ADMIN CONSOL",
  RPR_MNGT: "REPAIR MANAGEMENT",
  DP_CLAIMS: "DP CLAIMS",
  CHECK_IN: "Check In",
  ASP_ONBRD: "ASP ONBOARDING",
  EXCEPTIONS: "Exceptions",
  // ASP CONSOLE
  ASP_CONSOL: "ASP CONSOL",
  MAILIN: "MAILIN",
  WALKIN: "WALKIN",
  PUR: "PUR",
  HOMEPLUS: "HOMEPLUS",
  VAS: "VAS",
  // PARTS MANAGEMENT
  PARTS_MANAGEMENT: "PARTS MANAGEMENT",
  INVENTORY_MIN_MAX: "INVENTORY MIN MAX",
  INVENTORY_UPDATE_QTY: "INVENTORY UPDATE QTY",
  RMA: "RMA",
  INVENTORY_CYCLE_COUNT: "INVENTORY CYCLE COUNT",
  INVENTORY_FULFILLMENT: "INVENTORY FULFILLMENT",
  ASP_RECEIVING_SCREEN: "ASP RECEIVING SCREEN",
  JOBS: "JOBS",
  REQUESTS: "REQUESTS",
  SUR_ELIGIBILITY: "SUR ELIGIBILITY",
  ASSET_MANAGEMENT: "ASSET_MANAGEMENT",
  REPLACEMENT_MATRIX: "REPLACEMENT MATRIX",
  ADD_NEW_DEVICE: "ADD NEW DEVICE",
  SUPPORTED_DEVICE: "SUPPORTED DEVICES",
  AOC_RECEIVING_SCREEN: "AOC RECEIVING SCREEN",
  //GSX
  GSX_CONSOLE: "GSX CONSOLE",
  GSXMASTERUI: "GSXMASTERUI",
  GSX_ASN: "ASN",
  GSX_INVOICE_DETAILS: "Invoice Details",
  GSX_INVOICE_COMPARISON: "Invoice Comparison",
  GSX_REPAIR_COSTS: "Repair Costs",
  GSX_MAPPING_TABLE: "Mapping Table",
  GSX_SCM_APPROVE: "Update SCM Approve",
  GSX_FINANCE_APPROVE: "Update Finance Approve",
};

export const AspConsolButton = {
  CHECK_IN: "checkIn",
  UPDATE_INFO: "updateInfo",
  UPDATE_COST: "updateCost",
  QUALITY_CHECK: "qualityCheck",
  POST_INSPECT: "postInspect",
  NOTIFY_CUSTOMER: "notifyCustomer",
  CONFIRM_PAYMENT: "confirmPayment",
  CHECK_ELIGIBILITY: "checkEligibility",

  //TLC Repair Updae
  DEVICE_RECEIVED: "DEVICE RECEIVED",
  REPAIR_FEASIABLE: "REPAIR FEASIBLE",
  QUOTATION_RECEIVED: "QUOTATION RECEIVED",
  REVIEW_QUOTATION: "REVIEW QUOTATION",
  QUOTATION_PENDING_APPROVAL: "QUOTATION PENDING APPROVAL",
  STARTED: "STARTED",
  REPAIR_COMPLETED: "REPAIR_COMPLETED",
  READY_FOR_DELIVERY: "READY_FOR_DELIVERY",
  DELIVERY_SCHEDULE_REQUESTED: "DELIVERY_SCHEDULE_REQUESTED",
  DELIVERY_SCHEDULED: "DELIVERY_SCHEDULED",
  COMPLETE: "COMPLETE",

  // TLC Repair Overage.
  OVERAGE_QUOTATION_RECEIVED: "OVERAGE QUOTATION RECEIVED",
  AWAITING_CUSTOMER_ACCEPATANCE: "AWAITING CUSTOMER ACCEPATANCE",
  AWAITING_PAYMENT_FROM_CUSTOMER: "AWAITING PAYMENT FROM_CUSTOMER",
  PAYMENT_RECEIVED: "PAYMENT RECEIVED",
  REPAIR_COMPLETED: "REPAIR COMPLETED",
  READY_FOR_DELIVERY: "READY FOR DELIVERY",
  DELIVERY_SCHEDULE_REQUESTED: "DELIVERY SCHEDULE REQUESTED",
  DELIVERY_SCHEDULED: "DELIVERY SCHEDULED",
  COMPLETED: "COMPLETED",
  REPAIR_REJECTED: "REPAIR REJECTED",
  CANCELED: "CANCELED",
  REPAIR_INFEASIBLE: "REPAIR INFEASIBLE",
  AWAITING_CUSTOMER_SELECTION: "AWAITING CUSTOMER SELECTION",
  DEVICE_RETURN_EXPECTED_TO_ASURION: "DEVICE RETURN EXPECTED TO ASURION",
  PROCEED_WITH_PROCUREMENT: "PROCEED WITH PROCUREMENT",
  PROCUREMENT_COMPLETED: "PROCUREMENT COMPLETED",
  IN_PROGRESS: "IN PROGRESS",
  AWAITING_REPLACEMENT_CONFIGURATION: "AWAITING REPLACEMENT CONFIGURATION",
  RETURN_RECEIVED: "RETURN RECEIVED",
  READY_FOR_PROCUREMENT: "READY FOR PROCUREMENT",
  ONSITE_VISIT_SCHEDULED: "ONSITE VISIT SCHEDULED",
  AWAITING_DELIVERY_SCHEDULE: "AWAITING DELIVERY SCHEDULE",
  READY_FOR_REPAIR: "READY FOR REPAIR",
  DISPATCHED: "DISPATCHED",
  AWAITING_CLAIM_COMPLETION: "AWAITING CLAIM COMPLETION",
  PICK_UP_INPROGRESS: "PICKUP INPROGRESS",
  PICK_UP_FAILED: "PICKUP FAILED",
  PICK_UP_RESCHEDULED: "PICKUP RESCHEDULED",
  DELIVERY_FAILED: "DELIVERY FAILED",
  DELIVERY_RESCHEDULED: "DELIVERY RESCHEDULED",
  RETURN_FAILED: "RETURN FAILED",
  RETURN_RESCHEDULED: "RETURN RESCHEDULED",
  AWAITING_RETURN_SCHEDULE: "AWAITING RETURN SCHEDULE",
  RETURN_SCHEDULE_REQUESTED: "RETURN SCHEDULE REQUESTED",
  AWAITING_CLAIM_CANCELLATION: "AWAITING CLAIM CANCELLATION",
  PENDING_SCHEDULE_CONFIRMATION	:'PENDING SCHEDULE CONFIRMATION',
  SCHEDULE_CONFIRMED :'SCHEDULE CONFIRMED',
  SCHEDULE_REJECTED:'SCHEDULE REJECTED'
};

export const UpdateType = {
  TLCPRI: "TLCPRI", // TLC Pre-inspection
  TLCPRIRO: "TLCPRIRO", // TLC Pre-inspection ReadOnly
  TLCCHK: "TLCCHK", // TLC Check In
  ASPCHK: "ASPCHK", // ASP Check In
  ASPUPD: "ASPUPD", // ASP Update Repair
  ASPAUPD: "ASPAUPD", // ASP Update Repair (APPLE)
  ASPPRT: "ASPPRT", // ASP Update Parts Details
  ASPNTC: "ASPNTC", // ASP Notify Customer
  ASPPOS: "ASPPOS", // ASP Post Inspection
  ASPAQC: "ASPAQC", // ASP Quality Check (APPLE)
  TLCPOS: "TLCPOS", // TLC Post Inspection
  TLCCAC: "TLCCAC", // TLC Confirm Accessories
  TLCCRP: "TLCCRP", // TLC Confirm Repair
  TLCPAY: "TLCPAY", // TLC Confirm Payment
  UPDINF: "UPDINF", // Old Update Info Screen
  ASPPAY: "ASPPAY", // ASP Confirm Payment
  UPDINFHOMEPLUS: "UPDINFHOMEPLUS",
};

export const RepairStatus = {
  DISPATCHED: "DISPATCHED",
  AOC_FMIP_LOCKED: "AOC FMIP LOCKED",
  AOC_FMIP_UNLOCKED: "AOC FMIP UNLOCKED",
  DEVICE_RECEIVED: "DEVICE RECEIVED",
  POST_INSPECTION_COMPLETE: "POST INSPECTION COMPLETE",
  POST_INSPECTION_FAILED: "POST INSPECTION FAILED",
  PRE_INSPECTION_SUCCESSFUL: "PRE-INSPECTION SUCCESSFUL",
  POST_INSPECTION: "POST INSPECTION",
  CHECKED_IN: "CHECKED IN",
  CHECKIN: "CHECKIN",
  ASP_ASSIGNED: "ASP ASSIGNED",
  CC_PAYMENT_FAILED: "CC PAYMENT FAILED",
  SCHEDULED: "SCHEDULED",
  RWR_TLC: "RWR TLC",
  RWR_ASP: "RWR ASP",
  RWR_RETURNED: "RWR RETURNED",
  READY_FOR_DISPATCH: "READY FOR DISPATCH",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
  STARTED: "STARTED",
  PAYMENT_RECEIVED: "PAYMENT RECEIVED",
  ACCESSORIES_CONFIRMED: "ACCESSORIES CONFIRMED",
  AWAITING_QUALITY_CHECK: "AWAITING QUALITY CHECK",
  QUALITY_CHECK_COMPLETED: "QUALITY CHECK COMPLETED",
  ESCALATED: "ESCALATED",
  REPAIR_ELIGIBILITY_SUCCESSFUL: "REPAIR ELIGIBILITY SUCCESSFUL",
  REPAIR_ELIGIBILITY_FAILED: "REPAIR ELIGIBILITY FAILED",
  DISPATCHED_TO_ASP: "DISPATCHED TO ASP",
  DISPATCHED_TO_AOC: "DISPATCHED TO AOC",
  RE_REPAIR_COMPLETED: "RE REPAIR COMPLETED",
  //TLC Repair Update status Codes
  REPAIR_FEASIABLE: "REPAIR FEASIBLE",
  QUOTATION_RECEIVED: "QUOTATION RECEIVED",
  QUOTATION_APPROVED: "QUOTATION APPROVED",
  DELIVERY_SCHEDULE: "DELIVERY SCHEDULE",
  PROCEED_WITH_REPAIR: "PROCEED WITH REPAIR",
  AWAITING_CUSTOMER_SELECTION: "AWAITING CUSTOMER SELECTION",
  REVIEW_QUOTATION: "REVIEW QUOTATION",
  QUOTATION_PENDING_APPROVAL: "QUOTATION PENDING APPROVAL",
  OVERAGE_QUOTATION_RECEIVED: "OVERAGE QUOTATION RECEIVED",
  AWAITING_CUSTOMER_ACCEPATANCE: "AWAITING CUSTOMER ACCEPATANCE",
  AWAITING_PAYMENT_FROM_CUSTOMER: "AWAITING PAYMENT FROM CUSTOMER",
  REPAIR_COMPLETED: "REPAIR COMPLETED",
  READY_FOR_DELIVERY: "READY FOR DELIVERY",
  DELIVERY_SCHEDULE_REQUESTED: "DELIVERY SCHEDULE REQUESTED",
  DELIVERY_SCHEDULED: "DELIVERY SCHEDULED",
  COMPLETE: "COMPLETE",
  REPAIR_REJECTED: "REPAIR REJECTED",
  CANCELED: "CANCELED",
  REPAIR_INFEASIBLE: "REPAIR INFEASIBLE",
  AWAITING_CUSTOMER_SELECTION: "AWAITING CUSTOMER SELECTION",
  PROCEED_WITH_REPAIR: "PROCEED WITH REPAIR",
  DEVICE_RETURN_EXPECTED_TO_ASURION: "DEVICE RETURN EXPECTED TO ASURION",
  PROCEED_WITH_PROCUREMENT: "PROCEED WITH PROCUREMENT",
  PROCUREMENT_COMPLETED: "PROCUREMENT COMPLETED",
  IN_PROGRESS: "IN PROGRESS",
  AWAITING_REPLACEMENT_CONFIGURATION: "AWAITING REPLACEMENT CONFIGURATION",
  RETURN_RECEIVED: "RETURN RECEIVED",
  READY_FOR_PROCUREMENT: "READY FOR PROCUREMENT",
  ONSITE_VISIT_SCHEDULED: "ONSITE VISIT SCHEDULED",
  AWAITING_DELIVERY_SCHEDULE: "AWAITING DELIVERY SCHEDULE",
  READY_FOR_REPAIR: "READY FOR REPAIR",
  AWAITING_CLAIM_COMPLETION: "AWAITING CLAIM COMPLETION",
  PACKAGING_SHIPMENT: "PACKAGING SHIPMENT",
  PICK_UP_INPROGRESS: "PICKUP INPROGRESS",
  PICK_UP_FAILED: "PICKUP FAILED",
  PICK_UP_RESCHEDULED: "PICKUP RESCHEDULED",
  DELIVERY_FAILED: "DELIVERY FAILED",
  DELIVERY_RESCHEDULED: "DELIVERY RESCHEDULED",
  RETURN_FAILED: "RETURN FAILED",
  RETURN_RESCHEDULED: "RETURN RESCHEDULED",
  AWAITING_RETURN_SCHEDULE: "AWAITING RETURN SCHEDULE",
  RETURN_SCHEDULE_REQUESTED: "RETURN SCHEDULE REQUESTED",
  AWAITING_CLAIM_CANCELLATION: "AWAITING CLAIM CANCELLATION",
  PENDING_SCHEDULE_CONFIRMATION	:'PENDING SCHEDULE CONFIRMATION',
  SCHEDULE_CONFIRMED :'SCHEDULE CONFIRMED',
  SCHEDULE_REJECTED:'SCHEDULE REJECTED'
};

export const AocLocations = {
  AOC_APPLE: {
    Name: "Asurion Operations Centre",
    AddressLine: "D2/391 Park Rd ",
    Phone: "",
    Suburb: "Regents Park",
    State: "NSW",
    PostCode: "2143",
    CountryCode: "AUS",
    AssetMake: "APPLE",
    Client: ["HN", "DIGITAL", "TELSTRA", "CARE+"],
  },
  AOC_SAMSUNG: {
    Name: "Asurion Operations Centre",
    AddressLine: "D2/391 Park Rd ",
    Phone: "",
    Suburb: "Regents Park",
    State: "NSW",
    PostCode: "2143",
    CountryCode: "AUS",
    AssetMake: "SAMSUNG",
    Client: ["HN", "DIGITAL", "TELSTRA", "CARE+"],
  },
  AOC_TELSTRA: {
    Name: "Telstra SUR Mobile Refresh",
    AddressLine: "D2/391 Park Rd ",
    Phone: "",
    Suburb: "Regents Park",
    State: "NSW",
    PostCode: "2143",
    CountryCode: "AUS",
    AssetMake: "SAMSUNG",
    Client: ["HN", "DIGITAL", "TELSTRA", "CARE+"],
  },
};

export const RequestType = {
  MAILIN: "MAILIN",
  PUR: "PUR",
  WALKIN: "WALKIN",
  HOMEPLUS: "HomePlus",
  VAS: "VAS",
};

export const NotificationType = {
  AOC_FMIP_FAIL: {
    EventType: "HSRECEVFMIP",
    Desc: "Received- FMIP locked",
  },
  AOC_INSP_FAIL: {
    EventType: "HSAOCPRIF",
    Desc: "Received at Service - Greater than screen damage",
  },
  ASP_INSP_FAIL: {
    EventType: "HSASPPRIF",
    Desc: "Received at Service - Greater than screen damage",
  },
  ASP_POST_INSP_FAIL: {
    EventType: "HSASPPOIF",
    Desc: "Post Inspection failed ASP",
  },
  AOC_POST_INSP_FAIL: {
    EventType: "HSAOCPOIF",
    Desc: "Post Inspection failed AOC",
  },
  PAYMENT_FAIL: {
    EventType: "HSPYMTFAILED",
    Desc: "Received at Service - Credit card repair payment failure.",
  },
};

export const AspDecisionType = {
  SCR: "Screen Repair",
  FLR: "Fault Repair",
  RWR: "Return Without Repair (RWR)",
  BAT: "Battery Replacement",
  BSR: "Battery + Screen Replacement",
  ESC: "Escalation",
  ESCPROGRESS: "Escalation In Progress",
  DRF: "Device Refresh",
};
export const AspDecisionTypeHomePlus = {
  RF: "Repair Feasible",
  RR: "Repair Rejected",
  RINF: "Repair Infeasible",
  RC: "Repair Completed",
};
export const ClaimCompletionTypeHomePlus = {
  CCOM: "Claim Complete",
  CCAN: "Claim Cancel",
};
export const ScmDecisionTwOpenMarket = {
  AQ: "Approved Quotation",
  RQ: "Reject Quotation",
};
export const SREligibilityTwOpenMarket = {
  IWR: "Warranty Request",
  OOW: "Out Of Warranty",
};
export const CHTReasons = {
  CCOM: "Claim Complete",
  CREJ: "Claim Reject",
  ASCH: 'Approve Schedule',
  RSCH: "Reject Schedule"
};

export const DeviceMake = {
  Apple: "Apple",
  Samsung: "Samsung",
};

export const HeaderType = {
  ADMIN: "ADMIN",
  ASP: "ASP",
  PARTS: "PARTS",
  MATRIX: "MATRIX",
  GSX: "GSX",
};

export const OnSiteAspLocations = [
  "ICURE GRANVILLE",
  "OPPO Macquarie Park",
  "OPPO MLB",
  "OPPO",
  "AOC - SAMSUNG",
  "AOC - APPLE",
  "iCure Granville",
];

export const OnSiteAspLocationsTelstra = [
  "ICURE GRANVILLE",
  "iCure Granville",
  "AOC - SAMSUNG",
  "AOC - APPLE",
];

export const AOCAspLocations = [
  "ICURE GRANVILLE",
  "AOC - SAMSUNG",
  "AOC - APPLE",
];

export const PartsShipmentStatus = {
  ORDERED: "Ordered",
  SHIPPED: "Shipped",
  RECEIVED_BY_ASP: "Received By ASP",
  PARTIALLY_RECEIVED: "Partially Received",
};

export const RepairIncidentType = {
  ScreenRepair: "Screen Repair",
  FaultRepair: "Fault Repair",
  BatteryReplacement: "Battery Replacement",
  BatteryWithScreen: "Bat Scr Replacement",
  DeviceRefresh: "Device Refresh",
};

export const customerCaseStatus = {
  CNCLAGENT: "CNCLAGENT",
  CNCLCUS: "CNCLCUS",
  CNCLSYS: "CNCLSYS",
};

export const shortFormState = {
  "New South Wales": "NSW",
  Queensland: "QLD",
  "South Australia": "SA",
  "Western Australia": "WA",
  Victoria: "VIC",
  Tasmania: "TAS",
  "Australian Capital Territory": "ACT",
  "Northern Territory": "NT",
};

export const SwapStatus = {
  CHECKIN: "CHECKIN",
  
};
