import moment from "moment";
import {
  getStatusWiseCount,
  getRecordsAccessor,
  isEmpty,
} from "./../utils/utils";

const customerDataReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      let { user } = action.payload;
      let given_name = user.challengeName
        ? user.challengeParam.userAttributes.given_name
        : user.attributes.given_name;
      let family_name = user.challengeName
        ? user.challengeParam.userAttributes.family_name
        : user.attributes.family_name;
      let userEmail = user.challengeName
        ? user.challengeParam.userAttributes.email
        : user.attributes.email;
      return {
        ...state,
        user,
        userEmail: userEmail,
        username: `${given_name} ${family_name}`,
      };
    case "SET_SSO_USER":
      let { given_name: gName, family_name: fName, email } = action.payload;
      return {
        ...state,
        user: action.payload,
        userEmail: email,
        username: `${gName} ${fName}`,
      };
    case "SET_USERNAME":
      return {
        ...state,
        username: action.payload.username,
      };
    case "SET_USER_ROLES":
      let userRoles = [];
      let userClients = [];
      userRoles =
        isEmpty(action.payload) || isEmpty(action.payload.Role)
          ? []
          : action.payload.Role.map((r) => {
              return r.Active === true ? r.Name : "";
            });
      userClients =
        isEmpty(action.payload) || isEmpty(action.payload.Client)
          ? []
          : action.payload.Client.map((c) => {
              return c.Active === true ? c.ClientName : "";
            });
      let CHTSHTW =
        userClients.includes("SH_DEVICE_CARE") &&
        userClients.includes("CHT_HOME") &&
        userClients.includes("TWOpenMarket_SLW");
      let CHTSH =
        userClients.includes("SH_DEVICE_CARE") &&
        userClients.includes("CHT_HOME");
      let CHT = userClients.includes("CHT_HOME");
      let SH = userClients.includes("SH_DEVICE_CARE");
      let TW = userClients.includes("TWOpenMarket_SLW");

      return {
        ...state,
        userLoginId: action.payload?.LoginId,
        user: {
          ...state.user,
          additional: {
            ...state.user.additional,
            roles: userRoles,
            clients: userClients,
            clientDetails: action && action.payload && action.payload.Client,
            CHTSHTW,
            CHTSH,
            CHT,
            SH,
            TW,
          },
        },
      };
    case "SET_TABLE_FILTERS":
      return {
        ...state,
        ...action.payload,
      };
    case "CARRIER_CHANGED":
      return {
        ...state,
        clientName: action.payload.clientName,
        carrierChanged: action.payload.carrierChanged,
      };
    case "SET_LOADING_STATE":
      return {
        ...state,
        loadingState: action.payload.loading,
      };
    case "SET_LOGIN_LOADING":
      return {
        ...state,
        loginLoader: action.payload,
      };
    case "SET_RECORD_STATUS_FILTER":
      return {
        ...state,
        recordsStatusFilter: action.payload.recordsStatusFilter,
      };
    case "SET_INIT_DATA":
      return {
        ...state,
        initAppData: action.payload.initAppData,
        sessionId: action.payload.initAppData.Configurations.CacheId,
        correlationId: action.payload.initAppData.Configurations.correlationid
          ? action.payload.initAppData.Configurations.correlationid
          : undefined,
      };
    case "SET_RECORD_DATA":
      let {
        selectedTab,
        scheduledCount,
        inProgressCount,
        completedCount,
        scheduledCountWalkinClaims,
        inProgressCountWalkinClaims,
        completedCountWalkinClaims,
        isTLCTab,
        isWalkinClaimsTab,
      } = state;
      let records = getRecordsAccessor(selectedTab);
      let count = getStatusWiseCount(
        action.payload[records],
        isWalkinClaimsTab
      );
      return {
        ...state,
        ...action.payload,
        scheduledCountWalkinClaims: isWalkinClaimsTab && count.scheduled,
        inProgressCountWalkinClaims: isWalkinClaimsTab && count.inProgress,
        completedCountWalkinClaims: isWalkinClaimsTab && count.completed,
        scheduledCount:
          isTLCTab && !isWalkinClaimsTab ? count.scheduled : scheduledCount,
        inProgressCount:
          isTLCTab && !isWalkinClaimsTab ? count.inProgress : inProgressCount,
        completedCount:
          isTLCTab && !isWalkinClaimsTab ? count.completed : completedCount,
        loadingState: false,
      };
    case "SET_SELECTED_TAB":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_TLC_CURRENT_RECORD":
      return {
        ...state,
        activeTLCRecord: action.payload.record,
        multipleSelectRecords: [action.payload.record],
      };
    case "SET_POPUP":
      return {
        ...state,
        popup: action.payload,
      };
    case "SET_SSO":
      return {
        ...state,
        isSso: action.payload,
      };
    case "SET_MAKE_LIST":
      return {
        ...state,
        makeList: action.payload,
      };
    case "SET_MULTI_SELECT":
      const newRecord = action.payload;
      let updatedList = [...state.multipleSelectRecords];
      const recordIndex = updatedList.findIndex(
        (r) => r.rrNo === newRecord.rrNo
      );
      if (recordIndex === -1) {
        updatedList.push(newRecord);
      } else {
        updatedList.splice(recordIndex, 1);
      }
      return {
        ...state,
        multipleSelectRecords: updatedList,
      };
    case "CLEAR_MULTI_SELECT":
      return {
        ...state,
        multipleSelectRecords: [],
      };
    case "SET_JWT_DATA":
      return {
        ...state,
        jwtData: { ...action.payload },
      };
    case "CLEAR_JWT_DATA":
      return {
        ...state,
        jwtData: {},
      };
    case "SET_JWT_TOKEN":
      return {
        ...state,
        jwtToken: action.payload,
      };
    case "SET_PROGRAM_LIST":
      return {
        ...state,
        ProgramList: action.payload,
      };
    case "SET_CATEGORY_LIST":
      return {
        ...state,
        CategoryList: action.payload,
      };
    case "SET_SYSTEM_MASTER_DATA":
      return {
        ...state,
        SystemMasterData: action.payload,
      };
    case "SET_SELECTED_KEYS":
      return {
        ...state,
        SelectedRowKeys: action.payload,
      };
    case "SET_SELECTED_RECORDS":
      return {
        ...state,
        SelectedRecords: action.payload,
      };
    case "SET_SUCCESS_MSG":
      return {
        ...state,
        DeviceSuccess: action.payload || false,
      };
    case "SET_FAILURE_MSG":
      return {
        ...state,
        DeviceFailure: action.payload || false,
      };
    case "SET_CLIENT_OFFER_ID":
      return {
        ...state,
        clientOfferId: action.payload,
        programName: action.payload,
      };
    case "SET_CLIENT_OFFER_NAME":
      return {
        ...state,
        clientOfferName: action.payload,
      };
    case "SET_REPLACEMENT_DATA":
      return {
        ...state,
        ordersData: action.payload,
      };
    case "SET_MASTER_DATA":
      state.clientOfferName === "CHT Home" &&
        action.payload.map((data) => {
          return (data.CHtRRP = data.ModelRrp);
        });
      return {
        ...state,
        masterData: action.payload,
      };
    case "SET_PROGRAM_NAME":
      return {
        ...state,
        programName: action.payload,
      };
    case "SET_CATEGORY_NAME":
      return {
        ...state,
        category: action.payload,
      };
    case "SET_ASSET_CATEGORY_NAME":
      return {
        ...state,
        AssetCategoryName: action.payload,
      };

    case "SET_UPDATED_STATUS":
      return {
        ...state,
        updateStatusOfHomePlus: action.payload,
      };
    case "SET_SELECTED_CASENUMBER":
      return {
        ...state,
        updateStatusOfHomePlus: action.payload.caseNumber,
        updateStatusOfHomePlusRecord: action.payload.Record,
        fromASPTabToReplacementMtrix:
          action.payload.fromASPTabToReplacementMtrix,
      };
    /*  Labour Cost Event */
    case "SET_CLEAR_QUOTATION_STATE":
      const now = moment();
      const defaultWindow = now.add(2, "hours");
      return {
        ...state,
        LabourCost: 0,
        GstOnLabourCost: "",
        TotalOnLaburCost: "",
        RepairCost: "",
        GstOnRepairCost: "",
        TotalOnrRpairCost: "",
        repairETA: defaultWindow,
        etaReason: "",
        editedValues: "",
      };

    case "SET_LABOR_COST":
      return {
        ...state,
        LabourCost: action.payload,
      };
    case "SET_GST_ON_LABOR_COST":
      return {
        ...state,
        GstOnLabourCost: action.payload,
      };
    case "SET_TOTAL_ON_LABOR_COST":
      return {
        ...state,
        TotalOnLaburCost: action.payload,
      };
    /*   Repair Cost Event */
    case "SET_REPAIR_COST":
      return {
        ...state,
        RepairCost: action.payload,
      };
    case "SET_GST_ON_REPAIR_COST":
      return {
        ...state,
        GstOnRepairCost: action.payload,
      };
    case "SET_TOTAL_ON_REPAIR_COST":
      return {
        ...state,
        TotalOnrRpairCost: action.payload,
      };
    case "SET_TRANSPOR_COST":
      return {
        ...state,
        TransportCost: action.payload,
      };
    case "SET_GST_ON_TRANSPOR_COST":
      return {
        ...state,
        GstOnTransportCost: action.payload,
      };
    case "SET_TOTAL_ON_TRANSPOR_COST":
      return {
        ...state,
        TotalOnTransportCost: action.payload,
      };

    /*   Accessory Cost Event */
    case "SET_ACCESSORY_COST":
      return {
        ...state,
        AccessoryCost: action.payload,
      };
    case "SET_GST_ON_ACCESSORY_COST":
      return {
        ...state,
        GstOnAccessoryCost: action.payload,
      };
    case "SET_TOTAL_ON_ACCESSORY_COST":
      return {
        ...state,
        TotalOnrAccessoryCost: action.payload,
      };
    /* Set ETA */
    case "SET_ETA_REASON":
      return {
        ...state,
        etaReason: action.payload,
      };
    case "SET_REPAIR_ETA":
      return {
        ...state,
        repairETA: action.payload,
      };
    case "SET_FIELD_ARRAY":
      return {
        ...state,
        editedValues: action.payload,
      };
    case "SET_CASE_NUMBER":
      return {
        ...state,
        caseNumber: action.payload,
      };

      case "SET_SCM_DECISION":
        return {
          ...state,
          adminDecision: action.payload,
        };

        case "SET_SR_ELIGIBILITY":
          return {
            ...state,
            SrEligibility: action.payload,
          };

    case "SET_QUOTATION_DATA":
      let result = action.payload;
      const tax =
        (state &&
          state.ProgramList &&
          state.ProgramList[0] &&
          state.ProgramList[0].TaxPercentage) ||
        5;
      let objExist = action.payload && action.payload.quotationDetails;
      let repairCostOld =
        objExist && objExist.SUM_PRICE1_TO_PRICE10 - tax / 100; /* 0.07 */
      let labourCostRow = (objExist && objExist.PART_PRICE10) / (1 + tax / 100);
      let labourCostOld = labourCostRow && Math.round(labourCostRow);

      let transportCostRow =
        objExist && objExist.TRANSPORT_PRICE
          ? (objExist && objExist.TRANSPORT_PRICE) / (1 + tax / 100)
          : 0;
      let transportCostOld = transportCostRow && Math.round(transportCostRow);

      const totalPrice = objExist && objExist["SUM_PRICE1_TO_PRICE10"];
      const partNo = "PART_NUMBER";
      const partDesc = "PART_DESCRIPTION";
      const partPrice = "PART_PRICE";
      const partQty = "PART_QTY";
      const allParts = [partNo, partDesc, partPrice];
      const partIndexes = new Array(9).fill(0).map((v, idx) => idx + 1);
      let test =
        partIndexes &&
        partIndexes.reduce((acc, partNo) => {
          const partObj = allParts.reduce((record, name) => {
            const val = objExist && objExist[`${name}${partNo}`];
            if (val) {
              record[`${name}${partNo}`] = val;
            }
            return record;
          }, {});
          if (Object.keys(partObj).length > 0) acc.push(partObj);
          return acc;
        }, []);
      test = test.map((data, index) => {
        let partPrice =
          Number(data[`PART_PRICE${index + 1}`]) / (1 + tax / 100);
        let partPricenew = partPrice ? Math.round(partPrice) : "";
        let partPriceGst = (partPricenew * (tax / 100)).toFixed(2) || "";

        let abc = Object.assign({}, data, {
          PartNumber: data[`PART_NUMBER${index + 1}`],
          PartName: data[`PART_DESCRIPTION${index + 1}`],
          Total: Number(data[`PART_PRICE${index + 1}`]),
          PartCost: partPricenew,
          GST: partPriceGst,
          Availability: data["BACK_REASON"] ? "No" : "Yes",
          Comment: data["BACK_REASON"],
        });
        return abc;
      });
      let summ =
        test &&
        test.reduce(function (sum, current) {
          let data = current.PartCost; //.split('$')
          data = data; // && data[1]
          return sum + Number(data);
        }, 0);

      let GSTT =
        test &&
        test.reduce(function (sum, current) {
          let data = current.GST; //.split('$')
          data = data; //&& data[1]
          return sum + Number(data);
        }, 0);

      let Totall =
        test &&
        test.reduce(function (sum, current) {
          let data = current.Total; //.split('$')
          data = data; //&& data[1]
          return sum + Number(data);
        }, 0);

      let RepairCost = summ + labourCostOld + transportCostOld;
      let RepairGST =
        GSTT + labourCostOld * (tax / 100) + transportCostOld * (tax / 100);
      let RepairTotal =
        Totall +
        Number(objExist && objExist.PART_PRICE10) +
        Number(objExist && objExist.TRANSPORT_PRICE);
      return {
        ...state,
        editedValues: test,
        LabourCost: labourCostOld && labourCostOld.toFixed(2),
        GstOnLabourCost: `$${(labourCostOld * (tax / 100)).toFixed(2)}` || "",
        TotalOnLaburCost: `$${objExist && objExist.PART_PRICE10}` || "",
        TransportCost: transportCostOld && transportCostOld.toFixed(2),
        GstOnTransportCost:
          `$${(transportCostOld * (tax / 100)).toFixed(2)}` || "",
        TotalOnTransportCost:
          `$${
            objExist && objExist.TRANSPORT_PRICE
              ? objExist && objExist.TRANSPORT_PRICE
              : 0.0
          }` || "",
        RepairCost: `$${RepairCost}`,
        GstOnRepairCost: `$${RepairGST}`,
        //RepairTotal: RepairTotal,
        TotalOnrRpairCost: `$${RepairTotal.toFixed(2)}`, //objExist && objExist.SUM_PRICE1_TO_PRICE10,
        repairETA: moment(objExist.REPAIR_ETA),
        etaReason: objExist.ETA_REASON,
      };
    case "SET_ASPREPAIR_COST":
      return {
        ...state,
        ASPRepairCost: action.payload,
      };
    case "SET_ASPREPAIR_COST_ST":
      return {
        ...state,
        aspRepairCost: action.payload,
      };
    case "SET_POPUP_FLAG":
      return {
        ...state,
        popUpFlag: action.payload,
      };

    case "SET_OUTBOUND_SUCCESS_STATE":
      let newRecord1 = action.payload.record;
      newRecord1.outboundSuccess = action.payload.value;
      newRecord1.outboundResponse = action.payload.response;
      let list1 = [...state.currentTLCRecord, ...new Array(newRecord1)];

      return {
        ...state,
        currentTLCRecord: list1,
      };

    case "SET_INBOUND_SUCCESS_STATE":
      let newRecord2 = action.payload.record;
      newRecord2.inboundSuccess = action.payload.value;
      let list2 = [...state.currentTLCRecord, ...new Array(newRecord2)];

      return {
        ...state,
        currentTLCRecord: list2,
      };

    case "SET_OUTBOUND_MANIFEST_SUCCESS_STATE":
      let newRecord3 = action.payload.record;
      newRecord3.outboundManifestSuccess = action.payload.value;
      let list3 = [...state.currentTLCRecord, ...new Array(newRecord3)];

      return {
        ...state,
        currentTLCRecord: list3,
      };
    case "SET_MASTER_IMPORT_DATA":
      return {
        ...state,
        ...{
          GsxMasterUI: {
            ...state.GsxMasterUI,
            ...{
              gsxMasterImportData: action.payload.gsxMasterImportData,
              gsxMasterDuplicateData: action.payload.gsxMasterDuplicateData,
            },
          },
        },
      };
    case "SET_ASN_REF_NUMBER":
      return {
        ...state,
        ...{
          GsxASN: { ...state.GsxASN, ...{ asnRefNo: action.payload.asnRefNo } },
        },
      };
    case "SET_ASN_IMPORT_FILE_NAME":
      return {
        ...state,
        ...{
          GsxASN: {
            ...state.GsxASN,
            ...{ asnImportFileName: action.payload.asnImportFileName },
          },
        },
      };
    case "SET_ASN_IMPORT_DATA":
      return {
        ...state,
        ...{
          GsxASN: {
            ...state.GsxASN,
            ...{
              asnImportData: action.payload.asnImportData,
              asnDuplicateData: action.payload.asnDuplicateData,
            },
          },
        },
      };
    case "GET_ASN_DETAILS":
      return {
        ...state,
        ...{
          GsxASN: {
            ...state.GsxASN,
            ...{
              asnDetailsData: action.payload.asnDetailsData,
              asnExportDetails: action.payload.asnExportDetails,
            },
          },
        },
      };
    case "SET_BATCH_ID_INVOICE_DETAILS":
      return {
        ...state,
        ...{
          GsxInvoiceDetails: {
            ...state.GsxInvoiceDetails,
            ...{ batchIdInvoiceDetails: action.payload.batchIdInvoiceDetails },
          },
        },
      };
    case "SET_INVOICE_DETAILS_IMPORT_DATA":
      return {
        ...state,
        ...{
          GsxInvoiceDetails: {
            ...state.GsxInvoiceDetails,
            ...{
              invoiceDetailsImportData: action.payload.invoiceDetailsImportData,
              invoiceDetailsDuplicateData:
                action.payload.invoiceDetailsDuplicateData,
            },
          },
        },
      };

    case "SET_GET_INVOICE_DETAILS_RECORDS":
      return {
        ...state,
        ...{
          GsxInvoiceDetails: {
            ...state.GsxInvoiceDetails,
            ...{
              getInvoiceDetailsRecords: action.payload.getInvoiceDetailsRecords,
            },
          },
        },
      };

    case "SET_BATCH_ID_INVOICE_COMP":
      return {
        ...state,
        ...{
          GsxInvoiceComparison: {
            ...state.GsxInvoiceComparison,
            ...{ batchIDInvoiceComp: action.payload.batchIDInvoiceComp },
          },
        },
      };
    case "SET_REPAIR_COST_IMPORT_DATA":
      return {
        ...state,
        ...{
          GsxRepairCosts: {
            ...state.GsxRepairCosts,
            ...{
              repairCostImportData: action.payload.repairCostImportData,
              repairCostDuplicateData: action.payload.repairCostDuplicateData,
            },
          },
        },
      };
    case "SET_REPAIR_COST_REPAIR_TYPES_DATA":
      return {
        ...state,
        ...{
          GsxRepairCosts: {
            ...state.GsxRepairCosts,
            ...{
              repairTypeData: action.payload.repairTypeData,
              repairTypes: action.payload.repairTypes,
            },
          },
        },
      };
    case "SET_GSX_MASTER_DATA":
      return {
        ...state,
        ...{
          GsxMasterUI: {
            ...state.GsxMasterUI,
            ...{
              getGsxMasterRecord: action.payload.getGsxMasterRecord,
            },
          },
        },
      };
    case "SET_INVOICE_DETAILS_COMP_DATA":
      return {
        ...state,
        ...{
          GsxInvoiceComparison: {
            ...state.GsxInvoiceComparison,
            ...{
              getInvoiceDetailsCompRecords:
                action.payload.getInvoiceDetailsCompRecords,
            },
          },
        },
      };
    case "SET_REPAIR_COSTS_RECORDS":
      return {
        ...state,
        ...{
          GsxRepairCosts: {
            ...state.GsxRepairCosts,
            ...{
              getRepairCostRecords: action.payload.getRepairCostRecords,
            },
          },
        },
      };
    case "SET_MAPPING_TABLE_RECORDS":
      return {
        ...state,
        ...{
          GsxMappingTable: {
            ...state.GsxMappingTable,
            ...{
              getMappingTableRecords: action.payload.getMappingTableRecords,
            },
          },
        },
      };
    case "SET_THRESHOLD_FLAG":
      return { ...state, ...{ ThresholdFlag: action.payload } };
    case "SET_UPDATE_RESPONSE":
      return { ...state, ...{ Updateres: action.payload } };

    case "SET_UPDATE_RESPONSE_MSG":
      return { ...state, ...{ UpdateresMsg: action.payload } };
    case "SET_TRACKING_DATA":
      return {
        ...state,
        TrackingDetails: action.payload,
      };
    case "SET_TRACKING_DETAILS_FLAG":
      return {
        ...state,
        TrackingDetailsFlag: !state.TrackingDetailsFlag,
      };
    case "SET_ADD_NEW_DEVICE_FLAG":
      return {
        ...state,
        addNewDeviceFlag: action.payload,
        fileSelected: "",
      };
      case "SET_BULK_UPLOAD_FLAG":
        return {
          ...state,
          addBulkUploadFlag: action.payload,
          addNewDeviceFlag:false,
        };
        case "SET_SUMMARY_PAGE_FLAG":
          return {
            ...state,
            addBulkUploadFlag: false,
            addSummaryPageFlag:action.payload
          };
        
      case "SET_ADD_BULKUPLOAD_FILE_DATA":
        return {
          ...state,
          addRecords: action.payload.addRecords,
          updateRecords:action.payload.updateRecords,
          deleteRecords:action.payload.deleteRecords,
          invalidRecords:action.payload.invalidRecords
        };
        case "SET_FILE_SELECTED_FLAG":
          return {
            ...state,
            fileSelected: action.payload,
        };
        case "BUL_UPLOAD_BASE64_DATA":
          return {
            ...state,
            base64BulkUpload: action.payload,
        };
        case "SET_BULKUPLOAD_ERROR_MSG":
          return {
            ...state,
            errorMsg: action.payload,
        };
        case "FILE_BULK_UPLOAD_SUCCESS":
          return {
            ...state,
            bulkUploadSuccess: action.payload,
        };
        case "SET_GEO":
          return {
            ...state,
            geo: action.payload,
        };
        case "SET_CURRENCY":
          return {
            ...state,
            Currency: action.payload,
        };
        case "SET_FILE_HEADER_VALIDITY":
          return {
            ...state,
            findHeaderValidity: action.payload,
        };
        case "SET_CATEGORYLIST_COPY":
          return {
            ...state,
            CategoryListCopy: action.payload,
        };
        case "SET_INVALID_FILE":
          return {
            ...state,
            invalidFile: action.payload,
        };
        case "TCAT_API_FLAG":
          return {
            ...state,
            TcatApiFlag: action.payload.TcatApiFlag,
        };
        case "SET_MODAL_FLAG":
        return {
          ...state,
          modalFlag: action.payload,
        };
        case "SET_CLEANING_SR_TIMESLOT":
        return {
          ...state,
          cleaningSrTimeSlot: action.payload,
      };
    default:
      return state;
  }
};

export default customerDataReducer;
