import React, { Suspense } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import AuthState from "./Auth";
import { AuthConsumer } from "./helpers/AuthProvider";
import { useStateValue } from "./state";

const Login = React.lazy(() => import("./components/Login"));
const FirstLogin = React.lazy(() => import("./components/FirstLogin"));
// const Signup = React.lazy(() => import("./components/Signup"));
const ForgotPass = React.lazy(() => import("./components/ForgotPassword"));
const ChangePass = React.lazy(() => import("./components/ChangePassword"));
const Home = React.lazy(() => import("./components/Home"));

function PrivateRoute({ children, ...rest }) {
  const [{ isSso }, dispatch] = useStateValue(false);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return AuthState.isAuthenticated || isSso ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

function Routes(props) {
  return (
    <Switch>
      <Route path="/login" exact={true}>
        <Suspense fallback={"loading..."}>
          <AuthConsumer>
            {({ signinRedirect, generateToken }) => {
              return (
                <Login
                  props={props}
                  signinRedirect={signinRedirect}
                  generateToken={generateToken}
                />
              );
            }}
          </AuthConsumer>
        </Suspense>
      </Route>
      {/* <Route path="/signup" exact={true}>
        <Suspense fallback={"loading..."}>
          <Signup props={props} />
        </Suspense>
      </Route> */}
      <Route path="/forgot-password" exact={true}>
        <Suspense fallback={"loading..."}>
          <ForgotPass props={props} title={"Reset your forgotten password"} />
        </Suspense>
      </Route>
      <Route path="/reset-password" exact={true}>
        <Suspense fallback={"loading..."}>
          <ForgotPass props={props} title={"Reset your password"} />
        </Suspense>
      </Route>
      <PrivateRoute path="/change-password" exact={true}>
        <Suspense fallback={"loading..."}>
          <ChangePass props={props} />
        </Suspense>
      </PrivateRoute>
      <PrivateRoute path="/complete-profile" exact={true}>
        <Suspense fallback={"loading..."}>
          <FirstLogin props={props} />
        </Suspense>
      </PrivateRoute>
      <PrivateRoute path="/" exact={true}>
        <Suspense fallback={"loading..."}>
          <AuthConsumer>
            {({ signoutRedirectCallback }) => {
              return (
                <Home
                  props={props}
                  signoutRedirectCallback={signoutRedirectCallback}
                />
              );
            }}
          </AuthConsumer>
        </Suspense>
      </PrivateRoute>
      <Redirect exact to="/" />
    </Switch>
  );
}

export default withRouter(Routes);
