import { SsoConfig } from "../ssoConfig";
import { UserManager } from "oidc-client";
import config from "../config";
import callAPI from "../services/callApi";

export default class AuthService {
  UserManager;

  constructor() {
    this.UserManager = new UserManager({
      ...SsoConfig,
    });
    // // Logger
    // Log.logger = console;
    // Log.level = Log.DEBUG;
  }

  // signinRedirectCallback = () => {
  //   this.UserManager.signinRedirectCallback().then((data) => {
  //     // console.log(data);
  //   });
  // };

  signinRedirect = () => {
    this.UserManager.signinRedirect();
  };

  generateToken = async (code, state) => {
    let responseData = "";
    try {
      const localString = window.localStorage.getItem(`oidc.${state}`);
      const { client_id, code_verifier, redirect_uri } =
        JSON.parse(localString);
      window.localStorage.clear();
      responseData = await callAPI({
        method: "post",
        url: SsoConfig.metadata.token_endpoint,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "*/*",
        },
        data: `grant_type=authorization_code&code=${code}&client_id=${client_id}&code_verifier=${code_verifier}&redirect_uri=${redirect_uri}`,
      });
    } catch (err) {
      responseData = { error: "Something Went Wrong!" };
      console.error("Error::", err);
    }
    return responseData;
  };

  //   navigateToScreen = () => {
  //     window.location.replace("/en/dashboard");
  //   };

  //   isAuthenticated = () => {
  //     const oidcStorage = JSON.parse(
  //       sessionStorage.getItem(
  //         `oidc.user:${config.SSO.provider}:${config.SSO.clientId}`
  //       )
  //     );

  //     return !!oidcStorage && !!oidcStorage.access_token;
  //   };

  //   signinSilent = () => {
  //     this.UserManager.signinSilent()
  //       .then((user) => {
  //         console.log("signed in", user);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  //   signinSilentCallback = () => {
  //     this.UserManager.signinSilentCallback();
  //   };

  //   createSigninRequest = () => {
  //     return this.UserManager.createSigninRequest();
  //   };

  // logout = (token) => {
  //   // this.UserManager.signoutRedirect({
  //   // //   id_token_hint: localStorage.getItem("id_token"),
  //   //   id_token_hint: token,
  //   // });
  //   // this.UserManager.clearStaleState();

  //   this.UserManager.signoutRedirect();
  // };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      window.localStorage.clear();
      window.location.replace(config.SSO.redirectSignOut);
    });
    this.UserManager.clearStaleState();
  };
}
