import config from "./config";

const AuthState = {
  isAuthenticated: config.isLocal,
  authenticate(cb) {
    AuthState.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    AuthState.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

export default AuthState;
